var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InStore_wrapper" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.GoShopDetail, "label-width": "140px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "逛店规则", required: "" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入逛店规则",
                  maxlength: "200",
                  rows: "4",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.GoShopDetail.goShopDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.GoShopDetail, "goShopDesc", $$v)
                  },
                  expression: "GoShopDetail.goShopDesc"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "参与品牌门店", required: "" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "el-icon-plus",
                  attrs: { type: "primary" },
                  on: { click: _vm.ToEditBrand }
                },
                [_vm._v("选择品牌门店")]
              ),
              _c(
                "el-table",
                {
                  staticClass: "InStore_table",
                  attrs: {
                    data: _vm.GoShopDetail.brandList,
                    border: "",
                    stripe: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "品牌名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "品牌LOGO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.brandLogo,
                                  expression: "scope.row.brandLogo"
                                }
                              ],
                              staticStyle: {
                                display: "block",
                                width: "50px",
                                height: "50px",
                                margin: "0 10px 0 0"
                              },
                              attrs: { src: scope.row.brandLogo }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "门店信息" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.ToShopDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v("查看门店信息")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.DelBrand(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逛店数量", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { placeholder: "请输入逛店数量", maxlength: "4" },
                model: {
                  value: _vm.GoShopDetail.goShopTimes,
                  callback: function($$v) {
                    _vm.$set(_vm.GoShopDetail, "goShopTimes", $$v)
                  },
                  expression: "GoShopDetail.goShopTimes"
                }
              }),
              _c(
                "span",
                { staticStyle: { color: "#999999", "margin-left": "10px" } },
                [
                  _vm._v(
                    "达到逛店数量后，客户即可进行兑奖核销，请谨慎填写（0则默认需全逛店）"
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逛店礼品数量", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { placeholder: "请输入逛店礼品数量", maxlength: "4" },
                model: {
                  value: _vm.GoShopDetail.goShopNum,
                  callback: function($$v) {
                    _vm.$set(_vm.GoShopDetail, "goShopNum", $$v)
                  },
                  expression: "GoShopDetail.goShopNum"
                }
              }),
              _c(
                "span",
                { staticStyle: { color: "#999999", "margin-left": "10px" } },
                [
                  _vm._v(
                    "逛店礼品数量，用户达到逛店数量后，逛店礼品数量减1。当礼品数量为0时，用户将不允许再进行逛店。"
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitGoShop } },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "inStore_drawer",
          attrs: { visible: _vm.inStoreDrawer, center: "", width: "1600px" },
          on: {
            "update:visible": function($event) {
              _vm.inStoreDrawer = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("选择品牌门店")]),
          _c(
            "div",
            { staticClass: "select_wrap" },
            [
              _c(
                "div",
                [
                  _c("span", [_vm._v("品牌商类目：")]),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.BrandInfo.catId,
                        callback: function($$v) {
                          _vm.$set(_vm.BrandInfo, "catId", $$v)
                        },
                        expression: "BrandInfo.catId"
                      }
                    },
                    _vm._l(_vm.NewCategory, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("span", [_vm._v("请输入品牌商名称：")]),
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: true, placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.BrandInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.BrandInfo, "name", $$v)
                      },
                      expression: "BrandInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.searchPage }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "el-checkbox-group",
            {
              staticStyle: { display: "flex", "flex-flow": "wrap" },
              model: {
                value: _vm.checkedlist,
                callback: function($$v) {
                  _vm.checkedlist = $$v
                },
                expression: "checkedlist"
              }
            },
            [
              _c(
                "el-table",
                {
                  staticClass: "InStore_table",
                  attrs: { data: _vm.BrandList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: scope.row.id },
                                on: {
                                  change: function($event) {
                                    return _vm.addNewBrand(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.id))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "品牌名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandCatName", label: "品牌类目" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "品牌LOGO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.brandLogo,
                                  expression: "scope.row.brandLogo"
                                }
                              ],
                              staticStyle: {
                                display: "block",
                                width: "50px",
                                height: "50px",
                                margin: "0 10px 0 0"
                              },
                              attrs: { src: scope.row.brandLogo }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "门店信息" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.ToShopDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v("点击查看详细信息")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.inStoreDrawer = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.ConfirmSelectBrand }
                    },
                    [_vm._v("保 存")]
                  )
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.BrandInfo.page,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.BrandTotal
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "branch_drawer",
          attrs: { visible: _vm.BranchDrawer, center: "", width: "1600px" },
          on: {
            "update:visible": function($event) {
              _vm.BranchDrawer = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("门店信息")]),
          _c("p", { staticClass: "view_title" }, [
            _vm._v("品牌商：" + _vm._s(_vm.BrandName))
          ]),
          _c("div", { staticClass: "branch_btn" }, [
            _c(
              "div",
              { staticClass: "disflex ml10" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入门店名称" },
                  model: {
                    value: _vm.searchShopName,
                    callback: function($$v) {
                      _vm.searchShopName = $$v
                    },
                    expression: "searchShopName"
                  }
                }),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.getBrandShopList }
                  },
                  [_vm._v("搜索")]
                )
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.ShopList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "门店名称", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "联系电话", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "门店地址" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }