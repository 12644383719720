<template>
  <div class="InStore_wrapper">
    <el-form :model="GoShopDetail" label-width="140px">
      <el-form-item label="逛店规则" required>
        <el-input
          type="textarea"
          v-model="GoShopDetail.goShopDesc"
          placeholder="请输入逛店规则"
          maxlength="200"
          rows="4"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="参与品牌门店" required>
        <el-button type="primary" class="el-icon-plus" @click="ToEditBrand">选择品牌门店</el-button>
        <el-table class="InStore_table" :data="GoShopDetail.brandList" border stripe>
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column label="品牌LOGO">
            <template slot-scope="scope">
              <img
                v-show="scope.row.brandLogo"
                :src="scope.row.brandLogo"
                style="display:block;width:50px;height:50px;margin:0 10px 0 0;"
              />
            </template>
          </el-table-column>
          <el-table-column label="门店信息">
            <template slot-scope="scope">
              <el-tag type="primary" @click="ToShopDetail(scope.row)">查看门店信息</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tag type="danger" @click="DelBrand(scope.row.id)">移除</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="逛店数量" required>
        <el-input
          v-model="GoShopDetail.goShopTimes"
          placeholder="请输入逛店数量"
          maxlength="4"
          style="width:180px"
        ></el-input>
        <span style="color:#999999;margin-left:10px">达到逛店数量后，客户即可进行兑奖核销，请谨慎填写（0则默认需全逛店）</span>
      </el-form-item>
      <el-form-item label="逛店礼品数量" required>
        <el-input
          v-model="GoShopDetail.goShopNum"
          placeholder="请输入逛店礼品数量"
          maxlength="4"
          style="width:180px"
        ></el-input>
        <span style="color:#999999;margin-left:10px">逛店礼品数量，用户达到逛店数量后，逛店礼品数量减1。当礼品数量为0时，用户将不允许再进行逛店。</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitGoShop">保 存</el-button>
      </el-form-item>
    </el-form>

    <!-- 选择品牌门店 -->
    <el-dialog :visible.sync="inStoreDrawer" center width="1600px" class="inStore_drawer">
      <p class="view_title">选择品牌门店</p>
      <div class="select_wrap">
        <div>
          <span>品牌商类目：</span>
          <el-select v-model="BrandInfo.catId" placeholder="请选择">
            <el-option
              v-for="item in NewCategory"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <span>请输入品牌商名称：</span>
          <el-input class="ml10" :clearable="true" placeholder="请输入商品名称" v-model="BrandInfo.name"></el-input>
        </div>
        <el-button
          type="primary"
          class="ml10"
          icon="el-icon-search"
          @click="searchPage"
        >搜索</el-button>
      </div>
      <el-checkbox-group v-model="checkedlist" style="display: flex;flex-flow: wrap;">
        <el-table class="InStore_table" :data="BrandList" border stripe>
          <!-- @selection-change="handleSelectionChange" -->
          <!-- <el-table-column type="selection" width="60"></el-table-column> -->
          <el-table-column label="ID" width="200">
            <template slot-scope="scope">
              <el-checkbox @change="addNewBrand(scope.row)" :label="scope.row.id">{{scope.row.id}}</el-checkbox>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="brandCatId" label="品牌Id" width="100"></el-table-column> -->
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column prop="brandCatName" label="品牌类目"></el-table-column>
          <el-table-column label="品牌LOGO">
            <template slot-scope="scope">
              <img
                v-show="scope.row.brandLogo"
                :src="scope.row.brandLogo"
                style="display:block;width:50px;height:50px;margin:0 10px 0 0;"
              />
            </template>
          </el-table-column>
          <el-table-column label="门店信息">
            <template slot-scope="scope">
              <el-tag type="primary" @click="ToShopDetail(scope.row)">点击查看详细信息</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
        </el-table>
      </el-checkbox-group>
      <div class="tab-page">
        <div>
          <el-button @click="inStoreDrawer = false">取 消</el-button>
          <el-button type="primary" @click="ConfirmSelectBrand">保 存</el-button>
        </div>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="BrandInfo.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="BrandTotal"
        ></el-pagination>
      </div>
    </el-dialog>
    <!-- 门店信息 -->
    <el-dialog :visible.sync="BranchDrawer" center width="1600px" class="branch_drawer">
      <p class="view_title">门店信息</p>
      <p class="view_title">品牌商：{{BrandName}}</p>
      <div class="branch_btn">
        <div class="disflex ml10">
          <el-input v-model="searchShopName" placeholder="请输入门店名称"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="getBrandShopList">搜索</el-button>
        </div>
      </div>
      <el-table :data="ShopList" border stripe style="width: 100%">
        <el-table-column prop="name" label="门店名称" width="200"></el-table-column>
        <el-table-column prop="phone" label="联系电话" width="200"></el-table-column>
        <el-table-column prop="address" label="门店地址"></el-table-column>
      </el-table>
      <!-- <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange3"
          :current-page="ShopInfo.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="ShopTotal"
        ></el-pagination>
      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import { clone, formatTime } from "@/utils/utils";
export default {
  name: "InStore", // 活动-营销-逛店设置
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      inStoreViewWidth: "", // 窗体大小限制
      ShareAwardForm: {
        name: "",
        poster: "",
        radio: 0,
      },
      // ShareAwardRules: {
      //   goShopDesc: [{ required: true, message: "请输入逛店规则", trigger: "blur" }]
      // },
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      inStoreDrawer: false,

      SecondsKillInput: "",
      selectValue: "",
      selectOptions: [
        { value: "选项1", label: "黄金糕" },
        { value: "选项2", label: "双皮奶" },
        { value: "选项3", label: "蚵仔煎" },
        { value: "选项4", label: "龙须面" },
        { value: "选项5", label: "北京烤鸭" },
      ],
      pageIndex: 1, // 当前页码
      pageSize: 10, // 请求单前页码总条数
      secondsKillTotal: 0, // 总条数

      // 逛店所需参数
      GoShopDetail: {
        actId: 0,
        brandList: [],
        goShopDesc: "",
        goShopTimes:0,
      },
      multipleSelection: [],
      BrandList: [],
      BrandTotal: 0,
      BrandInfo: {
        actId: this.activityID,
        catId: "",
        name: "",
        page: 1,
        size: 10,
      },
      NewCategory: [],
      BrandId: 0,
      searchShopName: "",
      ShopList: [],
      BranchDrawer: false,
      BrandName: "",
      ShopInfo: {
        actId: 0,
        brandId: 0,
        name: "",
        page: 1,
        size: 1000,
      },
      checkedlist: [],
      checkListItems: [],
    };
  },
  computed: {},
  watch: {
    checkedlist: function (n) {
      this.checkList = clone(n);
    },
  },
  methods: {
    // 逛店品牌列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格 页码请求
    handleCurrentChange(val) {
      this.BrandInfo.page = val;
      this.getActivityBrandList();
    },
    searchPage() {
      this.BrandInfo.page = 1;
      this.getActivityBrandList();
    },
    delConfirm() {},
    // 逛店设置-查看，传入活动id(尚未创建时返回null）
    async getGoShop() {
      try {
        const data = await MarketingAjax.getGoShop(this.activityID);
        if (data.data) {
          this.GoShopDetail = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入品牌编辑或新增抽屉
    async ToEditBrand() {
      try {
        // 请求全部的品牌商列表
        this.BrandInfo = {
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 10,
        };
        await this.getActivityBrandList();
        // 请求军团类别
        if (this.NewCategory.length == 0) {
          await this.getNewCategory();
        }

        // 暂存已选的品牌商
        var arr = [];
        if (this.GoShopDetail.brandList) {
          this.GoShopDetail.brandList.forEach((item) => {
            arr.push(item.id);
          });
        }
        this.checkedlist = clone(arr);

        this.inStoreDrawer = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑逛店设置
    async submitGoShop() {
      try {
        var ids = [];
        // if (!GoShopDetail.goShopDesc) {
        //   this.$message.warning("请输入逛店规则");
        // }
        if (this.GoShopDetail.brandList.length > 0) {
          this.GoShopDetail.brandList.forEach((item) => {
            ids.push(item.id);
          });
        }
        if (!this.GoShopDetail.goShopTimes&&this.GoShopDetail.goShopTimes!=0) {
          this.$message.warning("请输入逛店数量,最小为0，最大为9999");
          return;
        }
        if (this.GoShopDetail.goShopTimes>this.GoShopDetail.brandList.length) {
          this.$message.warning("逛店数量需少于等于【参与品牌门店】数");
          return;
        }
        if (!this.GoShopDetail.goShopNum&&this.GoShopDetail.goShopNum!=0) {
          this.$message.warning("请输入逛店礼品数量,最小为0，最大为9999");
          return;
        }
        // else {
        //   this.$message.warning("请先选择品牌门店");
        // }
        if (!/(^[0-9]\d*$)/.test(this.GoShopDetail.goShopNum)) {
          this.$message.warning("逛店数量需为大于等于0的整数");
          return;
        }
        if (!/(^[0-9]\d*$)/.test(this.GoShopDetail.goShopTimes)) {
          this.$message.warning("逛店礼品数量需为大于等于0的整数");
          return;
        }
        var act = {
          actId: this.activityID,
          brandIds: ids,
          goShopDesc: this.GoShopDetail.goShopDesc,
          goShopTimes: Number(this.GoShopDetail.goShopTimes),//此处添加逛店数量
          goShopNum: Number(this.GoShopDetail.goShopNum) || 0,//此处添加逛店数量
        };
        await MarketingAjax.saveGoShop(act);
        this.$message.success("编辑逛店设置设置成功");
        await this.getGoShop();
      } catch (error) {
        this.$message.warning(error);
      }
    },
    // 获取品牌商列表
    async getActivityBrandList() {
      this.BrandInfo.actId = this.activityID;
      const {
        data: { list, total },
      } = await ActivityAjax.getActivityBrandList(this.BrandInfo);
      this.BrandList = list;
      this.BrandTotal = total;
    },
    // 获取最新的军团列表
    async getNewCategory() {
      try {
        var act = {
          actId: this.activityID,
          page: 1,
          size: 1000,
        };
        const {
          data: { list },
        } = await ActivityAjax.getBrandCategoryList(act);
        this.NewCategory = list;
      } catch (error) {
        this.$message.error(error);
      }
    },
    addNewBrand(i) {
      var ischecked = !$(event.target.labels[0]).hasClass("is-checked");
      if (ischecked) {
        this.checkListItems.push(i);
      } else {
        var cli = this.findi(this.checkList, function (el) {
          return el == i.id;
        });
        if (cli >= 0) {
          this.checkedlist.splice(cli, 1);
        }
      }
    },
    findi(arr, cb) {
      var index = -1;
      for (var ij = 0; ij < arr.length; ij++) {
        var el = arr[ij];
        var isok = cb(el);
        if (isok) {
          index = ij;
          break;
        }
      }
      return index;
    },
    // 确认选择品牌
    ConfirmSelectBrand() {
      var self = this;
      // 当前的已选
      var shoplist = this.GoShopDetail.brandList;

      var listss = [];
      // 合并旧已存在的 交集
      listss = shoplist.filter((el) => this.checkedlist.includes(el.id));
      console.log(listss);

      // 合并新选的 并集
      this.checkListItems.forEach((nel) => {
        // 当前项在选择列表中
        const idi = this.checkedlist.findIndex((elid) => elid === nel.id);
        // 当前项不在已选列表中
        const lidi = listss.findIndex((lid) => lid.id === nel.id);
        if (idi >= 0 && lidi === -1) {
          listss.push(nel);
        }
      });

      // for (var i = 0; i < self.multipleSelection.length; i++) {
      //   var item = self.multipleSelection[i];
      //   if (shoplist.length > 0) {
      //     for (var j = 0; j < shoplist.length; j++) {
      //       var item2 = shoplist[j];
      //       if (item.brandCatId == item2.brandCatId) {
      //       } else {
      //         shoplist.push(item);
      //       }
      //     }
      //   }
      // }
      console.log(listss);

      this.GoShopDetail.brandList = listss;
      this.inStoreDrawer = false;
    },
    // 移除品牌商
    DelBrand(Id) {
      this.$confirm(
        "移除后逛店页面将不再显示该品牌信息，确定移除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.GoShopDetail.brandList.splice(
            this.GoShopDetail.brandList.findIndex((item) => item.id == Id),
            1
          );
        })
        .catch(() => {});
    },
    // 门店列表
    async getBrandShopList() {
      try {
        var act = {
          actId: this.activityID,
          brandId: this.BrandId,
          name: this.searchShopName,
          page: 1,
          size: 1000,
        };
        const {
          data: { list },
        } = await ActivityAjax.getBrandShopList(act);
        this.ShopList = list;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async ToShopDetail(row) {
      this.addArmyTitle = "新建军团";
      this.ShopInfo = {
        actId: 0,
        brandId: row.id,
        name: "",
        page: 1,
        size: 1000,
      };
      this.searchShopName = "";
      this.ShopInfo.activityID = this.activityID;
      this.BrandName = row.name;
      this.BrandId = row.id;
      await this.getBrandShopList();
      this.BranchDrawer = true;
    },
  },
  created() {
    this.getGoShop();
  },
  mounted() {
    this.$nextTick(() => {
      $(".content").width() > 1280
        ? (this.inStoreViewWidth = "1280".toString())
        : (this.inStoreViewWidth = "80%");
    });
  },
};
</script>

<style lang="scss">
.InStore_wrapper {
  // 门店信息
  .branch_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .Brand_Owner {
      padding: 10px 0;
    }
    .branch_btn {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 20px;
      .el-input {
        margin-right: 10px;
      }
    }
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .InStore_table {
    margin-top: 20px;
    width: 100%;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .inStore_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .select_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      div {
        height: 40px;
        line-height: 40px;
        margin: 0 10px;
        .el-select {
          // width: 150px !important;
        }
        .el-input {
          width: 200px;
        }
      }
    }
    .el-drawer__body {
      padding: 0 20px;
      overflow-y: auto;
    }

    .tab-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
  }
}
</style>
